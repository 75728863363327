/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import ModuleMaker from 'vuex-module-maker';
import adminService from '../../services/admin';

const template = {
  instructions: {
    status: 'string',
    customers: {
      type: 'array',
      initial_value: [],
    },
    users: {
      type: 'array',
      initial_value: [],
    },
    user_customers: {
      type: 'array',
      initial_value: [],
    },
  },
  mutations: {
    addCustomer: (state, customer) => {
      state.customers.push(customer);
    },
    addUserCustomers: (state, customer) => {
      state.user_customers.push(customer);
    },
  },
  actions: { ...adminService },
};

const config = {
  namespace: true,
};

export default ModuleMaker.Make(template, config);
