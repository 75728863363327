import axios from 'axios';
import $const from '../constants';

export async function list({ commit, rootState }, params) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.FETCH_RISK_CARRIERS}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { data: riskCarriers } } = await axios.get(endpoint, config);
    commit('setRiskCarriers', riskCarriers);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

export async function create({ commit, rootState }, formData) {
  // clean for force reload with the new
  commit('setRiskCarriers', []);

  const { authModule: { token } } = rootState;
  let newRiskCarrier = null;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.STORE_RISK_CARRIER}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { riskCarrier } } = await axios.post(endpoint, formData, config);
    newRiskCarrier = riskCarrier;
    commit('setStatus', $const.API.STATUS.SUCCESS);
    return riskCarrier;
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }

  return newRiskCarrier;
}

export async function remove({ commit, rootState }, id) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.DELETE_RISK_CARRIER}${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.delete(endpoint, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    throw error;
  }
}

export async function update({ commit, rootState }, payload) {
  commit('setRiskCarriers', []);

  const { id, formData } = payload;
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.UPDATE_RISK_CARRIER}${id}`;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.put(endpoint, formData, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

export async function listCustomerRiskCarriers({ commit, rootState }, customerId) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.FETCH_CUSTOMER_RISK_CARRIERS}${customerId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // params: payload.params,
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { data: customerRiskCarriers } } = await axios.get(endpoint, config);
    commit('setCustomerRiskCarriers', customerRiskCarriers);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

export async function associateRiskCarriersWithCustomer({ commit, rootState }, payload) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ASSOCIATE_RISK_CARRIER_WITH_CUSTOMER}${payload.customerId}`;
  const config = { headers: { Authorization: `Bearer ${token}`, }, };
  const formData = { risk_carrier_id: payload.riskCarrierId };

   try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.put(endpoint, formData, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

async function dissociateRiskCarriersOfCustomer({ commit, rootState }, payload) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.DISSOCIATE_RISK_CARRIER_OF_CUSTOMER}${payload.customerId}`;
  const config = { headers: { Authorization: `Bearer ${token}`, }, };
  const formData = { risk_carrier_id: payload.riskCarrierId };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.put(endpoint, formData, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }

}

export default {
  list,
  remove,
  update,
  create,
  listCustomerRiskCarriers,
  associateRiskCarriersWithCustomer,
  dissociateRiskCarriersOfCustomer,
};
