<template>
  <div>
    <div class="bg-white">

      <nav class="flex flex-row justify-center">
          <button
            v-for="(option, index) in options"
            :key="`tab-btn-${index}`"
            class="tab-btn"
            :class="{'tab-btn-active' : index === activeTab}"
            @click="handleSwitchTab(index)"
          >
            {{ option }}
          </button>
      </nav>

      <div
        class="px-4"
        v-for="(option, index) in options"
        :key="`tab-content-${index}`"
      >
          <div v-if="index === activeTab" class="py-6">
            <slot :name="`${index + 1}`"></slot>
          </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      activeTab: 0,
    }
  },
  props: {
    options: {
      type: Array,
      required: true,
    }
  },
  methods: {
    handleSwitchTab(tab) {
      this.activeTab = tab;
    }
  }
}
</script>

<style lang="stylus" scoped>

  .tab-btn
    @apply text-gray-600 py-4 px-4 block transition duration-300 ease-in-out
    &:hover
      @apply text-purple-800
    &:focus
      @apply outline-none

  .tab-btn-active
    @apply text-purple-800 border-b-2 font-medium border-purple-800

</style>
