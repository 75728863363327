<template>
<!-- eslint-disable max-len -->
  <div class="min-h-screen">
    <!-- topbar -->
    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button/>
        <p class="pl-6">Fiche partenaire</p>
      </div>
      <div class="h-full flex items-center" slot="right">
        <delete-button
          @delete="isVisibleModal = true"
        />
      </div>
    </top-bar>

    <!-- main -->
    <div class="container mx-auto px-4 bg-white min-h-screen">
      <div v-if="customer">
        <div class="py-4">
          <div class="flex flex-col justify-center items-center pt-6">
            <img src="../../../public/img/bg/5.png" class="header-image" alt="">
          </div>

          <tabs :options="['Générales', 'Adresse', 'Contact']">
            <template v-slot:1>
              <!-- Nom commercial -->
              <div v-if="customer.commercial_name" class="border bg-white shadow-md mb-2 rounded border-gray-300">
                <div class="flex justify-center text-white bg-purple-800 py-1 rounded-t">
                  <span class="font-medium text-sm pl-2">Nom commercial</span>
                </div>
                <p class="text-center text-gray-800 py-4 font-medium">{{ customer.commercial_name }}</p>
              </div>

              <!-- Nom et Prénom du dirigeant -->
              <div v-if="customer.commercial_name" class="border bg-white shadow-md mb-2 rounded border-gray-300">
                <div class="flex justify-center text-white bg-purple-800 py-1 rounded-t">
                  <span class="font-medium text-sm pl-2">Dirigeant</span>
                </div>
                <p class="text-center text-gray-800 py-4 font-medium">{{ customer.leader_firstname }} {{ customer.leader_lastname }}</p>
              </div>

              <!-- Porteurs de risques -->
              <div v-if="customer.risk_carriers && customer.risk_carriers.length" class="border bg-white shadow-md mb-2 rounded border-gray-300">
                <div class="flex justify-center text-white bg-purple-800 py-1 rounded-t">
                  <span class="font-medium text-sm pl-2">Porteurs de risques</span>
                </div>
                <ul class="p-4">
                  <li
                    v-for="(riskCarrier, index) in customer.risk_carriers"
                    :key="`risk-carrier-${index}-${riskCarrier.id}`"
                    class="list-disc list-inside text-gray-800 font-medium"
                  >
                    {{ riskCarrier.commercial_name }}
                  </li>
                </ul>
              </div>

            </template>

            <template v-slot:2>
              <div v-if="address">
                <ul class="py-2">
                  <li v-if="address.country"><span class="font-medium">Pays : </span>{{ address.country }}</li>
                  <li v-if="address.state"><span class="font-medium">Région : </span>{{ address.state }}</li>
                  <li v-if="address.county"><span class="font-medium">Département : </span>{{ address.county }}</li>
                  <li v-if="address.postalCode"><span class="font-medium">Code postal : </span>{{ address.postalCode }}</li>
                  <li v-if="address.city"><span class="font-medium">Ville : </span>{{ address.city }}</li>
                  <li v-if="address.houseNumber"><span class="font-medium">Numéro : </span>{{ address.houseNumber }}</li>
                  <li v-if="address.street"><span class="font-medium">Rue : </span>{{ address.street }}</li>
                </ul>
              </div>
            </template>

            <template v-slot:3>
              <div class="flex flex-col items-center">
                <!-- Numéro de téléphone -->
                <a
                  v-if="customer.phone"
                  :href="`tel:${customer.phone}`"
                  target="_blank"
                  class="text-center mb-2 text-sm mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline">
                  {{ customer.phone }}
                </a>

                <!-- Adresse email -->
                <a
                  v-if="customer.email"
                  :href="`mailto:${customer.email}`"
                  target="_blank"
                  class="text-center mb-2 text-sm mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline">
                  {{ customer.email }}
                </a>
              </div>

            </template>

          </tabs>

        </div>
      </div>

      <spinner
        :is-visible="customer === null"
      ></spinner>
    </div>

    <transition
      mode="out-in"
      enter-active-class="animated fadeIn faster-x2"
      leave-active-class="animated fadeOut faster-x2"
    >
      <!-- confirm delete -->
      <alert-modal
        v-if="isVisibleModal"
        type="dialog"
        title="Supprimer ce partenaire ?"
        message="En supprimant ce partenaire, les données seront définitivement perdue."
        @cancel="isVisibleModal = false"
        @confirm="handleDeleteCustomer"
      />
    </transition>

     <!-- go to edit customer form -->
    <float-button
      v-if="customer"
      @tap="goTo($const.NAVIGATION.EDIT_CUSTOMER_PAGE.NAME, customer.id)">
      <edit-icon></edit-icon>
    </float-button>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
import Spinner from '../../components/Spinner.vue';
import FloatButton from '../../components/Buttons/FloatButton.vue';
import DeleteButton from '../../components/Buttons/DeleteButton.vue';
import { ICONS } from '../../constants';
import Tabs from '../../components/Tabs/Tabs.vue';

const { common: { EditIcon } } = ICONS;

export default {
  name: 'show-customer-page',
  components: {
    Spinner,
    FloatButton,
    EditIcon,
    DeleteButton,
    Tabs,
  },
  data() {
    return {
      customer: null,
      isVisibleModal: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters['authModule/getToken'];
    },
    address() {
      return JSON.parse(this.customer.address).address;
    },
  },
  methods: {
    handleDeleteCustomer() {
      const { id } = this.customer;
      this.customer = null;
      this.isVisibleModal = false;
      this.$store.dispatch('customersModule/remove', id).then(() => {
        this.$router.go(-1);
      });
    },
  },
  async mounted() {
    if (!this.$route.params.id) {
      this.$router.go(-1);
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const endpoint = `${this.$const.API.BASE_URL}${this.$const.API.ENDPOINTS.API_ENDPOINTS.FETCH_CUSTOMER}${this.$route.params.id}`;
      const { data: { data: customer } } = await this.$axios.get(endpoint, config);
      if (customer !== undefined) {
        this.customer = customer;
      }
    }
  },

};
</script>

<style lang="stylus">

</style>
