<template>
  <div v-if="this.userData">
    <!-- Top bar -->
    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Partenaires de {{ this.userData.name }}</p>
      </div>
    </top-bar>

    <!-- container -->
    <div class="container mx-auto p-4 bg-white">

      <transition
          enter-active-class="animated fadeIn faster"
          leave-active-class="animated fadeOut faster"
          mode="out-in"
      >
        <!-- input -->
        <customer-input-admin
          v-if="userCustomers.length || $const.API.STATUS.SUCCESS"
          placeholder="Ajouter un partenaire"
          @select="associateCustomerToUser"
          :excluded-id-list="excludedIdList"
        ></customer-input-admin>
      </transition>

      <!-- list customers attach to user -->
      <div>

        <transition
          enter-active-class="animated fadeIn faster"
          leave-active-class="animated fadeOut faster"
          mode="out-in"
        >
          <ul v-if="userCustomers.length">
            <li
              class="py-2 my-2 flex items-center justify-between shadow-sm border bg-white rounded px-3"
              v-for="customer in userCustomers"
              :key="`user-customer-${customer.id}`"
            >
              <p class="font-bold text-gray-800">{{ customer.commercial_name }}</p>
              <delete-button @delete="dissociateCustomerOfUser(customer)" />

            </li>
          </ul>

          <!-- Loader -->
          <spinner v-else-if="status === $const.API.STATUS.LOADING" :is-visible="true"></spinner>

          <p v-else class="text-gray-500 text-center p-10">Aucun partenaire n'est associée à cet utilisateur.</p>
        </transition>

      </div>
    </div>

     <!-- modal confirm dissociate customer from user -->
    <transition
      mode="out-in"
      enter-active-class="animated fadeIn faster-x2"
      leave-active-class="animated fadeOut faster-x2"
    >
      <alert-modal
        v-if="displayModalDissociateCustomer"
        type="dialog"
        :title="`Dissocier le partenaire ?`"
        :message="`Souhaitez-vous vraiment dissocier le partenaire ${this.userCustomerSelected.commercial_name} de l'utilisateur ${this.userData.name} ?`"
        @cancel="displayModalDissociateCustomer = false"
        @confirm="confirmDissociateCustomerOfUser"
      />
    </transition>

  </div>
</template>

<script>
import CustomerInputAdmin from '../../components/Inputs/CustomerInputAdmin.vue';
import DeleteButton from '../../components/Buttons/DeleteButton.vue';

export default {
  components: {
    CustomerInputAdmin,
    DeleteButton,
  },
  name: 'user-customers-admin-page',
  data() {
    return {
      userData: null,
      params: {
        limit: 100,
        offset: 0,
      },
      displayModalDissociateCustomer: false,
      userCustomerSelected: null,
    }
  },
  methods: {
    associateCustomerToUser(customer) {
      this.$store.dispatch('adminModule/associateCustomerOfUser', {
        userId: this.userData.id,
        customerId: customer.id,
      }).then(() => {

        this.getUserCustomers();
      });
    },
    getUserCustomers() {
      if (this.status !== this.$const.API.STATUS.LOADING && this.userData) {
        this.$store.dispatch('adminModule/listUserCustomers', {
          params: this.params,
          userId: this.userData.id,
        }).then(() => {

          // Handle invalid token even if user is authenticated
          if (this.status === this.$const.API.STATUS.UNAUTHORIZED) {
            this.$store.dispatch('authModule/logout').then(() => {
              this.$router.push({ name: this.$const.NAVIGATION.LOGIN_PAGE.NAME });
            });
          }

        });
      }
    },
    dissociateCustomerOfUser(customer) {
      this.userCustomerSelected = customer;
      this.displayModalDissociateCustomer = true;
    },
    confirmDissociateCustomerOfUser() {
      this.$store.dispatch('adminModule/dissociateCustomerOfUser', {
        userId: this.userData.id,
        customerId: this.userCustomerSelected.id,
      }).then(() => {
        this.getUserCustomers();
        this.displayModalDissociateCustomer = false;
      });
    }
  },
  computed: {
    status() {
      return this.$store.getters['adminModule/getStatus'];
    },
    userCustomers: {
      get() {
        return this.$store.getters['adminModule/getUserCustomers'].sort((a, b) => {
          if (a.commercial_name < b.commercial_name) {
            return -1;
          }
        });
      },
      set(userCustomers) {
        this.$store.commit('adminModule/setUserCustomers', userCustomers);
      }
    },
    excludedIdList() {
      return this.userCustomers.map((customer) => customer.id);
    }
  },
  mounted() {
    if (!this.$route.params.user) {
      this.$router.go(-1);
    } else {
      this.userData = this.$route.params.user;
      this.getUserCustomers();
    }
  },
  destroyed() {
    this.userCustomers = [];
  },
}
</script>

<style>

</style>
