<template>
<!-- delete button -->
  <div
    @click="$emit('delete')"
    class="flex justify-center items-center delete-icon-wrapper"
  >
    <delete-icon class="delete-icon"></delete-icon>
  </div>
</template>

<script>
import { ICONS } from '../../constants';

const { common: { DeleteIcon } } = ICONS;

export default {
  name: 'DeleteButton',
  components: {
    DeleteIcon,
  },
};
</script>

<style lang="stylus" scoped>
.delete-icon-wrapper
  height 23px
  width 23px

  .delete-icon
    height 23px
    width 23px
    color theme('colors.red.600')
    fill theme('colors.red.600')
</style>
