import axios from 'axios';
import $const from '../constants';

export async function listCustomers({ commit, rootState }, params) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ADMIN.FETCH_CUSTOMERS}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { data: customers } } = await axios.get(endpoint, config);
    commit('setCustomers', customers);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

export async function listUsers({ commit, rootState }, params) {

  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ADMIN.FETCH_USERS}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { data: users } } = await axios.get(endpoint, config);
    commit('setUsers', users);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

export async function listUserCustomers({ commit, rootState }, payload) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ADMIN.FETCH_USER_CUSTOMERS}${payload.userId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: payload.params,
  };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    const { data: { data: userCustomers } } = await axios.get(endpoint, config);
    commit('setUserCustomers', userCustomers);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

// remove relationship between customer and user selected
export async function dissociateCustomerOfUser({ commit, rootState }, payload) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ADMIN.DISSOCIATE_CUSTOMER_OF_USER}${payload.userId}`;
  const config = { headers: { Authorization: `Bearer ${token}`, }, };
  const formData = { customer_id: payload.customerId };

  try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.put(endpoint, formData, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }
}

// link customer with the user selected
export async function associateCustomerOfUser({ commit, rootState }, payload) {
  const { authModule: { token } } = rootState;
  const endpoint = `${$const.API.BASE_URL}${$const.API.ENDPOINTS.API_ENDPOINTS.ADMIN.ASSOCIATE_CUSTOMER_OF_USER}${payload.userId}`;
  const config = { headers: { Authorization: `Bearer ${token}`, }, };
  const formData = { customer_id: payload.customerId };

   try {
    commit('setStatus', $const.API.STATUS.LOADING);
    await axios.put(endpoint, formData, config);
    commit('setStatus', $const.API.STATUS.SUCCESS);
  } catch (error) {
    commit('setStatus', $const.API.STATUS.ERROR);
    if (error.response) {
      if (error.response.status === 401) {
        commit('setStatus', $const.API.STATUS.UNAUTHORIZED);
      }
    }
  }


}

export default {
  listCustomers,
  listUserCustomers,
  listUsers,
  dissociateCustomerOfUser,
  associateCustomerOfUser,
}
