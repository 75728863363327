
export default {
  data() {
    return {
      isConfirmLogoutModalVisible: false,
    };
  },
  methods: {
    logout() {
      this.isConfirmLogoutModalVisible = false;
      this.$store.dispatch('authModule/logout').then(() => {
        this.$router.push({ name: this.$const.NAVIGATION.LOGIN_PAGE.NAME });
      });
    },
  },
};
