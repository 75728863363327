export default {
  LOGIN: '/api/login',

  FETCH_REPORTS: '/api/reports',
  UPDATE_REPORT: '/api/report/',
  FETCH_REPORT: '/api/report/',

  FETCH_USER: '/api/user/',

  FETCH_CUSTOMERS: '/api/customers',
  FETCH_CUSTOMER: '/api/customer/',
  DELETE_CUSTOMER: '/api/customer/',
  UPDATE_CUSTOMER: '/api/customer/',

  FETCH_SETTINGS: '/api/settings',

  FETCH_APPOINTMENTS: '/api/appointments',
  FETCH_APPOINTMENT: '/api/appointment/',
  STORE_APPOINTMENT: '/api/appointment',
  DELETE_APPOINTMENT: '/api/appointment/',
  UPDATE_APPOINTMENT: '/api/appointment/',

  SEND_NEWSLETTER: '/api/newsletter/customers',

  STORE_RISK_CARRIER: '/api/risk-carrier',
  FETCH_RISK_CARRIERS: '/api/risk-carriers',
  DELETE_RISK_CARRIER: '/api/risk-carrier/',
  UPDATE_RISK_CARRIER: '/api/risk-carrier/',

  FETCH_CUSTOMER_RISK_CARRIERS: '/api/customer-risk-carriers/',
  ASSOCIATE_RISK_CARRIER_WITH_CUSTOMER: '/api/associate-risk-carrier-with-customer/',
  DISSOCIATE_RISK_CARRIER_OF_CUSTOMER: '/api/dissociate-risk-carrier-of-customer/',

  ADMIN: {
    SEND_NEWSLETTER: '/api/admin/newsletter/customers',

    FETCH_USERS: '/api/users',

    FETCH_CUSTOMERS: '/api/admin/customers',

    FETCH_USER_CUSTOMERS: '/api/admin/user-customers/',
    DISSOCIATE_CUSTOMER_OF_USER: '/api/admin/dissociate-customer-of-user/',
    ASSOCIATE_CUSTOMER_OF_USER: '/api/admin/associate-customer-of-user/',
  }
};
