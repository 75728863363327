<template>
  <div @click="handleForceCloseDropDown" class="h-screen">

    <!-- Top bar -->
    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Gestion des utilisateurs</p>
      </div>
    </top-bar>

    <!-- container -->
    <div class="container mx-auto p-4 bg-white">

      <!-- spinner pending switch account -->
      <spinner
        :isVisible="true"
        v-if="isSwitchingAccount"
      ></spinner>

      <!-- admin features : switch account -->
      <div v-else class="flex flex-col justify-center items-center">
        <div v-if="userRole === 'ROLE_ADMIN'" class="w-full">
          <input-search
            id="search-user"
            :status="usersModuleStatus"
            placeholder="Rechercher un utilisateur"
            @search="handleSearchUser"
          ></input-search>
          <transition
            mode="out-in"
            enter-active-class="animated fadeIn faster-x2"
            leave-active-class="animated fadeOut faster-x2"
          >
            <!-- if no result user search -->
            <div v-if="hasNoResult">
              <p class="text-gray-500 text-center py-5 px-10">Aucun résultat</p>
            </div>

            <!-- list users founded -->
            <div v-if="users && users.length > 0">
              <div
                v-for="user in users"
                :key="`user-${user.id}`"
                class="py-2 my-2 flex items-center justify-between border shadow-sm bg-white rounded px-3"
              >
                <p class="font-bold text-gray-800">{{ user.name }}</p>
                <simple-dropdowns
                  :is-active="userSelected && user.id === userSelected.id && toggleDropdowns"
                  @active="onActiveUserDropDowns(user)"
                  @select="onSelectDropdownsOption"
                  :options="dropdownsOptions"
                ></simple-dropdowns>
              </div>
            </div>
          </transition>
        </div>
      </div>  <!-- / admin features : switch account -->

    </div> <!-- / container -->


    <!-- modal confirm switch account -->
    <transition
      mode="out-in"
      enter-active-class="animated fadeIn faster-x2"
      leave-active-class="animated fadeOut faster-x2"
    >
      <alert-modal
        v-if="displayModalSwitchAccount"
        type="dialog"
        :title="`Se connecter en tant que ${this.userSelected.name} ?`"
        message="Attention, si vous confirmez, n'oubliez pas de vous déconnecter puis de vous reconnecter sur votre compte admin."
        @cancel="displayModalSwitchAccount = false"
        @confirm="confirmSwitchAccount"
      />
    </transition>

  </div>
</template>

<script>
import SimpleDropdowns from '../../components/Dropdowns/SimpleDropdowns.vue';
import InputSearch from '../../components/Inputs/InputSearch.vue';
import logout from '../../mixins/logout';


export default {
  name: 'users-admin-page',
  mixins: [logout],
  components: {
    InputSearch,
    SimpleDropdowns,
  },
  data() {
    return {
      usersSearchParams: {
        limit: 10,
        offset: 0,
        by: 'user_name',
        value: '',
      },
      isSwitchingAccount: false,
      userToSwitchHasNoToken: false,
      hasNoResult: false,
      displayModalSwitchAccount: false,
      userSelected: null,
      toggleDropdowns: false,
      dropdownsOptions: [
        {label: 'Se connecter au compte', key: 'switch-account'},
        {label: 'Gérer les partenaires', key: 'handle-customers'},
      ],
    };
  },
  computed: {
    userRole() {
      return this.$store.getters['authModule/getUserRole'];
    },
    users: {
      get() {

        // Hide current user logged in list of user
        // let currentUserLogged = this.$store.getters['authModule/getUser'];
        // return this.$store.getters['adminModule/getUsers']
        //   .filter(user => currentUserLogged.id !== user.id);

        return this.$store.getters['adminModule/getUsers'];
      },
      set(value) {
        this.$store.commit('adminModule/setUsers', value);
      },
    },
    usersModuleStatus() {
      return this.$store.getters['adminModule/getStatus'];
    },
    token: {
      get() {
        return this.$store.getters['authModule/getToken'];
      },
      set(value) {
        this.$store.commit('authModule/setToken', value);
      },
    },
    currentUserLogged: {
      get() {
        return this.$store.getters['authModule/getUser'];
      },
      set(value) {
        this.$store.commit('authModule/setUser', value);
      },
    },
  },
  methods: {
    handleSearchUser(userName = null) {
      this.users = [];
      this.hasNoResult = false;
      this.usersSearchParams.value = userName;

      if (this.usersModuleStatus !== this.$const.API.STATUS.LOADING) {
        this.$store.dispatch('adminModule/listUsers', this.usersSearchParams).then(() => {

          // handle no result
          if (this.users.length === 0) {
            this.hasNoResult = true;
          }

          // Handle invalid token even if user is authenticated
          if (this.usersModuleStatus === this.$const.API.STATUS.UNAUTHORIZED) {
            this.logout();
          }
        });

      }

    },
    switchAccount(userSelected) {
      this.userSelected = userSelected;
      this.displayModalSwitchAccount = true;
    },
    async confirmSwitchAccount() {
      this.isSwitchingAccount = true;
      const endpoint = `${this.$const.API.BASE_URL}${this.$const.API.ENDPOINTS.API_ENDPOINTS.FETCH_USER}${this.userSelected.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };

      const { data } = await this.$axios.get(endpoint, config);

      if (data.token && data.user) {
        this.token = data.token;
        this.currentUserLogged = data.user;
        this.$router.push({ name: this.$const.NAVIGATION.HOME_PAGE.NAME });
      }
    },
    onActiveUserDropDowns(userSelected) {
      if (this.userSelected && this.userSelected.id === userSelected.id) {
        this.toggleDropdowns = !this.toggleDropdowns;
      } else {
        this.toggleDropdowns = true;
        this.userSelected = userSelected;
      }
    },
    handleForceCloseDropDown(event) {
      if (event.target.getAttribute('data-button') !== 'dropdown' && event.target.parentNode &&
      event.target.parentNode.getAttribute('data-button') !== 'dropdown') {
        this.toggleDropdowns = false;
      }
    },
    onSelectDropdownsOption(optionKey) {
      switch (optionKey) {
        case 'handle-customers':
          this.$router.push({
            name: this.$const.NAVIGATION.CUSTOMERS_USER_ADMIN_PAGE.NAME,
            params: { user: this.userSelected },
          });
          break;
        case 'switch-account':
          this.switchAccount(this.userSelected);
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.handleSearchUser();
  },
}
</script>

<style>

</style>
