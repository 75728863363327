<template>
  <div class="min-h-screen bg-white">
    <!-- topbar -->
    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Mon rapport</p>
      </div>
    </top-bar>

    <!-- main -->
    <div class="container mx-auto px-4">

      <!-- form -->
      <edit-report-form
        v-if="report"
        :survey="survey"
        :report="report"
        :status="status"
        @submit="handleSubmitResponses($event)"
      >
        <risk-carrier-input
          @search="handleSearchRiskCarrier"
          @associate="handleAssociateRiskCarrierWithCustomer"
          @dissociate="handleDissociateRiskCarrierOfCustomer"
          :can-create="true"
          label="Porteur(s) de risques :"
          placeholder="Rechercher"
          :risk-carriers="riskCarriers"
          :customer-risk-carriers="customerRiskCarriers"
          :status="status"
        ></risk-carrier-input>
      </edit-report-form>


      <!-- spinner -->
      <spinner
        :is-visible="report === null"
      ></spinner>

    </div>
  </div>
</template>

<script>
import EditReportForm from '../../components/Forms/EditReportForm.vue';
import Spinner from '../../components/Spinner.vue';
import RiskCarrierInput from '../../components/Inputs/RiskCarrierInput.vue';

export default {
  name: 'edit-report-page',
  data() {
    return {
      report: null,
    };
  },
  components: {
    EditReportForm,
    Spinner,
    RiskCarrierInput,
  },
  methods: {
    handleSubmitResponses(responses) {
      this.$store.dispatch('reportsModule/update', {
        id: this.$route.params.id,
        responses: JSON.stringify(responses),
      }).then(() => this.$router.push({ name: this.$const.NAVIGATION.LIST_REPORT_PAGE.NAME }));
    },
    // créer l'association entre le porteur de risque et le client
    handleAssociateRiskCarrierWithCustomer(riskCarrier) {
      this.$store.commit('riskCarriersModule/setRiskCarriers', []);
      this.$store.dispatch('riskCarriersModule/associateRiskCarriersWithCustomer', {
        riskCarrierId: riskCarrier.id,
        customerId: this.report.customer.id,
      }).then(() => {
        this.$store.dispatch('riskCarriersModule/listCustomerRiskCarriers', this.report.customer.id);
      });
    },
    handleSearchRiskCarrier(params) {
      this.$store.commit('riskCarriersModule/setRiskCarriers', []);
      this.$store.dispatch('riskCarriersModule/list', params);
    },
    // supprime l'association entre le porteur de risque et le client
    handleDissociateRiskCarrierOfCustomer(customerRiskCarrier) {
      this.$store.dispatch('riskCarriersModule/dissociateRiskCarriersOfCustomer', {
        riskCarrierId: customerRiskCarrier.id,
        customerId: this.report.customer.id,
      }).then(() => {
        // refresh list customer risk carriers
        this.$store.dispatch('riskCarriersModule/listCustomerRiskCarriers', this.report.customer.id)
      });
    },
  },
  computed: {
    survey() {
      return this.$store.getters['settingsModule/getSurvey'];
    },
    status() {
      return this.$store.getters['reportsModule/getStatus'];
    },
    token() {
      return this.$store.getters['authModule/getToken'];
    },
    riskCarriers() {
      return this.$store.getters['riskCarriersModule/getRiskCarriers'];
    },
    customerRiskCarriers() {
      return this.$store.getters['riskCarriersModule/getCustomerRiskCarriers'];
    },
  },
  async mounted() {
    if (!this.$route.params.id) {
      this.$router.go(-1);
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const endpoint = `${this.$const.API.BASE_URL}${this.$const.API.ENDPOINTS.API_ENDPOINTS.FETCH_REPORT}${this.$route.params.id}`;
      const { data: { data: report } } = await this.$axios.get(endpoint, config);

      if (report !== undefined) {
        this.report = report;
        this.$store.dispatch('riskCarriersModule/listCustomerRiskCarriers', report.customer.id)
      }

    }
  },
};

</script>

<style>

</style>
