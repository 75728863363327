<template>
<!-- eslint-disable max-len -->
  <div class="modal w-full h-full fixed top-0 flex justify-center items-center p-4">
    <div class="bg-white shadow-xl rounded w-full">
      <div class="p-4 flex flex-col items-center">
        <h2 class="font-bold text-xl text-center p-3">{{ title }}</h2>
        <div class="p-3 flex justify-between items-center">
          <input
            type="text"
            class="h-10 pl-3 bg-transparent text-lg text-gray-700 border rounded-l-sm focus:outline-none"
            :placeholder="placeholder"
            v-model="value"
          >
          <button @click="$emit('submit', value)" class="rounded-r-sm h-10 px-4 bg-purple-800 flex justify-center items-center">
            <add-icon class="icon add-icon"></add-icon>
          </button>
        </div>
      </div>
      <div class="flex justify-between items-center">

        <!-- cancel -->
        <button
          class= "w-full rounded-br text-center bg-gray-200 border-gray-200 p-3 border text-black text-lg font-medium"
          @click="$emit('cancel')">
          {{ labelCloseButton }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import { ICONS } from '../../constants';

const { common: { AddIcon } } = ICONS;

export default {
  name: 'alert-modal',
  data() {
    return {
      value: '',
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    labelCloseButton: {
      type: String,
      required: false,
      default: 'Annuler',
    }
  },
  components: {
    AddIcon,
  },
  destroyed() {
    this.value = '';
  }
};
</script>

<style lang="stylus">
.modal
  background rgba(0,0,0,0.6);
  z-index 10;

.icon
  height 13px
  width 13px

.add-icon
  fill theme('colors.white')
  color theme('colors.white')

</style>
