<template>
  <button
    @click="updateActiveFilter"
    class="rounded-full mr-2 px-2 py-1 text-sm border font-medium mb-2"
    :class="[ activeFilter === value ? activeClass : 'default' ]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    activeFilter: {
      type: String,
      required: true,
    },
    activeClass: {
      type: String,
      default: 'border-purple-800 bg-purple-800 text-white shadow-inner',
    }
  },
  methods: {
    updateActiveFilter() {
      this.$emit('onUpdateFilter', this.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .default
    @apply text-gray-500 shadow-inner bg-white
</style>
