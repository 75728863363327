import ModuleMaker from 'vuex-module-maker';
import riskCarriersService from '../../services/risk-carriers';

const template = {
  instructions: {
    status: 'string',
    riskCarriers: {
      type: 'array',
      initial_value: [],
    },
    customerRiskCarriers: {
      type: 'array',
      initial_value: [],
    }
  },
  actions: { ...riskCarriersService },
  mutations: {
    addRiskCarrier: (state, riskCarrier) => {
      state.riskCarriers.push(riskCarrier);
    },
  },
};

const config = {
  namespaced: true,
};

export default ModuleMaker.Make(template, config);
