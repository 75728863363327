// admin
export default {
  INDEX_ADMIN_PAGE: { NAME: 'admin-index', PATH: '/admin' },
  HOME_ADMIN_PAGE: { NAME: 'admin-home', PATH: '' },
  NEWSLETTER_ADMIN_PAGE: { NAME: 'admin-newsletter', PATH: 'newsletter' },
  USERS_ADMIN_PAGE: { NAME: 'admin-users', PATH: 'users' },
  CUSTOMERS_USER_ADMIN_PAGE: { NAME: 'admin-customers-user', PATH: 'user' },
  RISK_CARRIERS_ADMIN_PAGE: { NAME: 'admin-risk-carriers', PATH: 'risk-carriers'},

};
