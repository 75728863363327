<template>
<!-- eslint-disable max-len -->
  <div class="py-2">

    <label class="block pb-2 font-semibold text-gray-800 text-lg" for="risk-carrier">
      {{ label }}
      <small v-if="label" class="font-light text-sm">(facultatif)</small>
    </label>

    <!-- Input for search -->
    <div class="relative appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-12 flex justify-between">
      <input
        title="risk-carrier"
        class="text-lg px-3 bg-transparent absolute h-full w-5/6 left-0 top-0 text-gray-700"
        id="risk-carrier"
        type="search"
        v-model="params.commercial_name"
        @click="handleClearField($event)"
        @keyup="searchRiskCarrier($event)"
        :placeholder="placeholder"
      >
      <span
        @click="searchRiskCarrier($event)"
        class="w-1/6 absolute right-0 top-0 h-full flex justify-center items-center"
      >
          <moon-loader
            class="v-spinner-custom"
            v-if="status === $const.API.STATUS.LOADING"
            :color="$const.MISC.SPINNER.COLOR"
          ></moon-loader>
          <magnify-icon v-else class="magnify-icon"></magnify-icon>
      </span>
    </div>

    <!-- Dropdown result for select or create -->
    <transition
      enter-active-class="animated fadeIn faster-x2"
      leave-active-class="animated fadeOut faster-x2"
      mode="out-in"
    >
      <div v-if="riskCarriers && riskCarriers.length > 0 || params.commercial_name.length >= 2">
        <ul class="risk-carriers-list">
          <li
            v-for="(riskCarrier, index) in riskCarriersFiltered"
            :key="`risk-carrier-${index}-${riskCarrier.id}`"
            @click="handleSelectRiskCarrier($event, riskCarrier)"
            class="py-2 risk-carrier-item flex justify-between items-center"
          >
            <span class="w-5/6 h-full" >
              {{ riskCarrier.commercial_name }}
            </span>
            <span class="flex justify-center items-center w-1/6">
              <add-icon class="add-icon"></add-icon>
            </span>
          </li>
        </ul>
      </div>
    </transition>

    <!-- list customer risk carriers -->
    <transition
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <ul v-if="customerRiskCarriers && customerRiskCarriers.length > 0">
        <li
          class="py-2 my-2 flex items-center justify-between shadow-sm border bg-white rounded px-3"
           v-for="(customerRiskCarrier, index) in customerRiskCarriers"
        :key="`customer-risk-carrier-${index}-${customerRiskCarrier.id}`"
        >
          <p class="font-bold text-gray-800">{{ customerRiskCarrier.commercial_name }}</p>
          <delete-button @delete="dissociateRiskCarrierOfCustomer(customerRiskCarrier)" />
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { ICONS } from '../../constants';
import DeleteButton from '../Buttons/DeleteButton.vue';

const { common: { AddIcon, MagnifyIcon } } = ICONS;

export default {
  name: 'risk-carrier-input',
  components: {
    AddIcon,
    MagnifyIcon,
    DeleteButton,
  },
  props: {
    label: String,
    placeholder: String,
    canCreate: {
      type: Boolean,
      default: false,
    },
    riskCarriers: {
      type: Array,
      required: true,
    },
    customerRiskCarriers: {
      type: Array,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      params: {
        commercial_name: '',
        limit: 4,
        offset: 0,
      },
      riskCarrierSelected: null,
      riskCarrierFieldsVisible: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchRiskCarrier: debounce(function () {
      if (this.status !== this.$const.API.STATUS.LOADING) {
        if (this.params.commercial_name.length >= 2) {
          this.$emit('search', this.params);
        }
      }
    }, 300),
    handleSelectRiskCarrier(event, riskCarrier) {
      this.riskCarrierSelected = riskCarrier;
      this.$emit('associate', this.riskCarrierSelected);
    },
    handleClearField() {
      this.params.commercial_name = '';
      this.riskCarrierSelected = null;
    },
    // permet de savoir si on peut en créer un nouveau ou non
    IsCommercialNameAlreadyExist(search) {
      const commercialNames = this.riskCarriers.map(
        (riskCarrier) => riskCarrier.commercial_name.toLowerCase(),
      );

      if (commercialNames.includes(search.toLowerCase())) {
        return true;
      }

      return false;
    },
    // supprime l'association entre le porteur de risque et le client
    dissociateRiskCarrierOfCustomer(customerRiskCarrier) {
      this.$emit('dissociate', customerRiskCarrier);
    },
  },
  computed: {
    // le résultat de la recherche ne doit pas comporter les porteurs de risques déjà associés au client
    riskCarriersFiltered() {
      return this.riskCarriers.filter((riskCarrier) => {
        let commercialNamesCustomerRiskCarriers = this.customerRiskCarriers.map((customerRiskCarrier) => {
          return customerRiskCarrier.commercial_name;
        });
        return !commercialNamesCustomerRiskCarriers.includes(riskCarrier.commercial_name);
      });
    }
  },
};
</script>

<style lang="stylus">
.input-form
  @apply appearance-none border
  @apply w-full py-2 px-3 text-gray-700
  @apply leading-tight
  border-width theme('borderWidth.default')
  border-color theme('borderColor.teal.600')
  border-top none
  border-left none
  border-right none

.risk-carriers-list
  .risk-carrier-item
    @apply border-b text-purple-800
    transition all 0.2s

.v-spinner-custom
  transform scale(.5)
</style>
