<template>
<!-- eslint-disable max-len -->
  <div>
    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Menu administrateur</p>
      </div>
    </top-bar>

    <div class="container mx-auto p-4 bg-white">

      <button
        @click="goTo($const.NAVIGATION.NEWSLETTER_ADMIN_PAGE.NAME)"
        class="mb-2 text-lg mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline"
      >
        Créer une newsletter globale
      </button>

      <button
        @click="goTo($const.NAVIGATION.USERS_ADMIN_PAGE.NAME)"
        class="mb-2 text-lg mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline"
      >
        Gestion des utilisateurs
      </button>

      <button
        @click="goTo($const.NAVIGATION.RISK_CARRIERS_ADMIN_PAGE.NAME)"
        class="text-lg mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline"
      >
        Gestion des porteurs de risques
      </button>

    </div>
  </div>
</template>

<script>

export default {
  name: 'home-admin-page',
};
</script>

<style>

</style>
