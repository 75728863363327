// routes: admin

import IndexAdminPage from '../../pages/admin-pages/IndexAdminPage.vue';
import HomeAdminPage from '../../pages/admin-pages/HomeAdminPage.vue';
import NewsletterAdminPage from '../../pages/admin-pages/NewsletterAdminPage.vue';
import UsersAdminPage from '../../pages/admin-pages/UsersAdminPage.vue';
import UserCustomersAdminPage from '../../pages/admin-pages/UserCustomersAdminPage.vue';
import RiskCarriersAdminPage from '../../pages/admin-pages/RiskCarriersAdminPage.vue';

import $const from '../../constants';

export default {
  path: $const.NAVIGATION.INDEX_ADMIN_PAGE.PATH,
  component: IndexAdminPage,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: $const.NAVIGATION.HOME_ADMIN_PAGE.PATH,
      name: $const.NAVIGATION.HOME_ADMIN_PAGE.NAME,
      component: HomeAdminPage,
    },
    {
      path: $const.NAVIGATION.NEWSLETTER_ADMIN_PAGE.PATH,
      name: $const.NAVIGATION.NEWSLETTER_ADMIN_PAGE.NAME,
      component: NewsletterAdminPage,
    },
    {
      path: $const.NAVIGATION.USERS_ADMIN_PAGE.PATH,
      name: $const.NAVIGATION.USERS_ADMIN_PAGE.NAME,
      component: UsersAdminPage,
    },
    {
      path: $const.NAVIGATION.CUSTOMERS_USER_ADMIN_PAGE.PATH,
      name: $const.NAVIGATION.CUSTOMERS_USER_ADMIN_PAGE.NAME,
      component: UserCustomersAdminPage,
    },
    {
      path: $const.NAVIGATION.RISK_CARRIERS_ADMIN_PAGE.PATH,
      name: $const.NAVIGATION.RISK_CARRIERS_ADMIN_PAGE.NAME,
      component: RiskCarriersAdminPage,
    }
  ],
};
