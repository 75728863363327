import common from './common';
import login from './login';
import appointment from './appointment';
import customer from './customer';

export default {
  common,
  login,
  appointment,
  customer,
};
