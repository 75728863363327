<template>
<!-- eslint-disable max-len -->
  <div class="min-h-screen">
    <!-- top bar -->
    <top-bar></top-bar>

    <!-- Main -->
    <div class="container mx-auto px-4" v-infinite-scroll="handleFetchMore" infinite-scroll-distance="1">

      <div class="py-4">
        <!-- search -->
        <div class="pb-4">
          <input-search
            id="customers"
            placeholder="Rechercher un partenaire"
            :status="status"
            @search="handleSearchCustomer"
          >
          </input-search>
        </div>

        <div class="flex flex-wrap">
          <filter-button id="all-filter-button"
            value="asc"
            :active-filter="params.order"
            @onUpdateFilter="$event => params.order = $event">
            A - Z
          </filter-button>

          <filter-button id="uncompleted-filter-button"
            value="desc"
            :active-filter="params.order"
            @onUpdateFilter="$event => params.order = $event">
            Z - A
          </filter-button>
        </div>
      </div>

      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <ul v-if="customers && customers.length" class="grid grid-cols-2 gap-2">
          <li v-for="customer in customers" :key="customer.id">
            <customer-item
              @delete="handleOnDelete($event)"
              :customer="customer"
            />
          </li>
        </ul>
        <spinner v-else-if="status === $const.API.STATUS.LOADING" :is-visible="true"></spinner>
        <p v-else class="text-gray-500 text-center px-10">Aucun résultat</p>
      </transition>
    </div>
  </div>
</template>

<script>
import CustomerItem from '../../components/Customers/CustomerItem.vue';
import Spinner from '../../components/Spinner.vue';
import InputSearch from '../../components/Inputs/InputSearch.vue';
import FilterButton from '../../components/Filter/FilterButton.vue';

export default {
  name: 'list-customer-page',
  components: {
    CustomerItem,
    Spinner,
    InputSearch,
    FilterButton,
  },
  data() {
    return {
      params: {
        limit: 10,
        offset: 0,
        commercial_name: '',
        order: 'asc',
      },
    };
  },
  watch: {
    'params.order'() {
      this.clearCustomers();
      this.fetchCustomers();
    },
  },
  methods: {
    async handleOnDelete(customerId) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const endpoint = `${this.$const.API.BASE_URL}${this.$const.API.ENDPOINTS.API_ENDPOINTS.DELETE_CUSTOMER}${customerId}`;
      await this.$axios.delete(endpoint, config);
    },
    handleSearchCustomer(commercialName = '') {
      this.params.offset = 0;
      this.params.commercial_name = commercialName;
      this.clearCustomers();
      this.fetchCustomers();
    },
    fetchCustomers() {
      this.$store.dispatch('customersModule/list', this.params).then(() => {
        // Handle invalid token even if user is authenticated
        if (this.$store.getters['customersModule/getStatus'] === this.$const.API.STATUS.UNAUTHORIZED) {
          this.$store.dispatch('authModule/logout').then(() => {
            this.$router.push({
              name: this.$const.NAVIGATION.LOGIN_PAGE.NAME,
            });
          });
        }
      });
    },
    handleFetchMore() {
      if (this.status !== this.$const.API.STATUS.LOADING) {
        if (this.params.offset !== this.customers.length) {
          if (this.customers.length % 10 === 0) {
            this.params.offset = this.customers.length;
            this.fetchCustomers();
          }
        }
      }
    },
    clearCustomers() {
      this.params.limit = 10;
      this.params.offset = 0;
      this.$store.commit('customersModule/setCustomers', []);
    },
  },
  computed: {
    customers() {
      return this.$store.getters['customersModule/getCustomers'];
    },
    token() {
      return this.$store.getters['authModule/getToken'];
    },
    status() {
      return this.$store.getters['customersModule/getStatus'];
    },
  },
  destroyed() {
    this.clearCustomers();
  },
  mounted() {
    this.clearCustomers();
    this.fetchCustomers();
  },
};
</script>
