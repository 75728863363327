<template>
  <div>

   <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Porteurs de risques</p>
      </div>
    </top-bar>

    <div class="container mx-auto px-4">

      <div class="py-4">
        <!-- search -->
        <div class="pb-4">
          <input-search
            id="risk-carriers"
            @search="handleSearchRiskCarrier"
            :status="status"
            placeholder="Rechercher"
          >
          </input-search>
        </div>

        <!-- list customer risk carriers -->
        <transition
          enter-active-class="animated fadeIn faster"
          leave-active-class="animated fadeOut faster"
          mode="out-in"
        >
          <ul v-if="riskCarriers && riskCarriers.length > 0">
            <li
              class="py-2 my-2 flex items-center justify-between shadow-sm border bg-white rounded px-3"
              v-for="(riskCarrier, index) in riskCarriers"
            :key="`risk-carrier-${index}-${riskCarrier.id}`"
            >
              <p class="font-bold text-gray-800">{{ riskCarrier.commercial_name }}</p>
              <delete-button @delete="handleDeleteRiskCarrier(riskCarrier)" />
            </li>
          </ul>

          <spinner v-else-if="status === $const.API.STATUS.LOADING" :is-visible="true"></spinner>
          <p v-else class="text-gray-500 text-center px-10">Aucun résultat</p>

        </transition>

      </div>
    </div>

    <!-- create modal -->
    <input-modal
      v-if="isCreateModalVisible"
      title="Ajouter un porteur de risques"
      placeholder="Nom du porteur"
      @cancel="isCreateModalVisible = false"
      @submit="handleCreateRiskCarrier"
    ></input-modal>

    <!-- confirm modal -->
    <alert-modal
      v-if="isConfirmModalVisible"
      type="dialog"
      :title="`Supprimer ${riskCarrierSelected.commercial_name} ?`"
      message="Souhaitez-vous vraiment supprimé ce porteur de risques de tous vos partenaires existants ?"
      @cancel="isConfirmModalVisible = false"
      @confirm="confirmDeleteRiskCarrier"
    />

    <!-- success modal -->
    <alert-modal
      type="success"
      title="Supprimé !"
      message="Porteur de risques supprimé avec succès !"
      v-if="isSuccessModalVisible"
      @cancel="isSuccessModalVisible = false"
      label-close-button="Fermer"
    ></alert-modal>

    <float-button @tap="isCreateModalVisible = true">
      <add-icon></add-icon>
    </float-button>

  </div>
</template>

<script>
import DeleteButton from '../../components/Buttons/DeleteButton.vue';
import InputSearch from '../../components/Inputs/InputSearch.vue';
import InputModal from '../../components/Modals/InputModal.vue';
import FloatButton from '../../components/Buttons/FloatButton.vue';
import { ICONS } from '../../constants';

const { common: { AddIcon } } = ICONS;


export default {
  name: 'risk-carriers-admin-page',
  components: {
    InputSearch,
    DeleteButton,
    FloatButton,
    AddIcon,
    InputModal,
  },
  data() {
    return {
      riskCarrierSelected: null,
      isConfirmModalVisible: false,
      isSuccessModalVisible: false,
      isCreateModalVisible: false,
      params: {
        limit: 100,
        offset: 0,
        commercial_name: '',
        order: 'asc',
      },
    }
  },
  methods: {
    handleSearchRiskCarrier(commercialName) {
      this.params.commercial_name = commercialName;
      this.clearRiskCarriers();
      this.fetchRiskCarriers();
    },
    fetchRiskCarriers() {
      this.$store.dispatch('riskCarriersModule/list', this.params).then(() => {
        // Handle invalid token even if user is authenticated
        if (this.$store.getters['riskCarriersModule/getStatus'] === this.$const.API.STATUS.UNAUTHORIZED) {
          this.$store.dispatch('authModule/logout').then(() => {
            this.$router.push({
              name: this.$const.NAVIGATION.LOGIN_PAGE.NAME,
            });
          });
        }
      });
    },
    handleFetchMore() {
      if (this.status !== this.$const.API.STATUS.LOADING) {
        if (this.params.offset !== this.customers.length) {
          if (this.riskCarriers.length % 10 === 0) {
            this.params.offset = this.riskCarriers.length;
            this.fetchRiskCarriers();
          }
        }
      }
    },
    clearRiskCarriers() {
      this.params.limit = 100;
      this.params.offset = 0;
      this.$store.commit('riskCarriersModule/setRiskCarriers', []);
    },
    handleCreateRiskCarrier(commercial_name) {
      this.$store.dispatch('riskCarriersModule/create', {
        commercial_name,
      }).then(() => {
        this.$store.dispatch('riskCarriersModule/list', this.params)
      });
      this.$store.commit('riskCarriersModule/setRiskCarriers', []);
    },
    confirmDeleteRiskCarrier() {
      this.isConfirmModalVisible = false;
      this.$store.dispatch('riskCarriersModule/remove', this.riskCarrierSelected.id).then(() => {

        if (this.$store.getters['riskCarriersModule/getStatus'] === this.$const.API.STATUS.UNAUTHORIZED) {
          this.$store.dispatch('authModule/logout').then(() => {
            this.$router.push({
              name: this.$const.NAVIGATION.LOGIN_PAGE.NAME,
            });
          });
        }

        this.isSuccessModalVisible = true;
        this.params.commercial_name = '';
        this.clearRiskCarriers();
        this.fetchRiskCarriers();
      });
    },
    handleDeleteRiskCarrier(riskCarrier) {
      this.riskCarrierSelected = riskCarrier;
      this.isConfirmModalVisible = true;
    },
  },
  computed: {
    status() {
      return this.$store.getters['riskCarriersModule/getStatus'];
    },
    riskCarriers() {
      return this.$store.getters['riskCarriersModule/getRiskCarriers'];
    },
  },
  destroyed() {
    this.clearRiskCarriers();
  },
  mounted() {
    this.clearRiskCarriers();
    this.fetchRiskCarriers();
  },
}
</script>

<style>

</style>
