<template>
<!-- eslint-disable max-len -->
  <div class="min-h-screen">
    <!-- top bar -->
    <top-bar></top-bar>

    <!-- Main -->
    <div class="container mx-auto p-4"  v-infinite-scroll="handleFetchMore" infinite-scroll-distance="1">
      <!-- filtres -->
      <div class="flex flex-col py-4">

        <!-- par status du rapport -->
        <div class="flex">
          <filter-button id="all-filter-button"
            value="all"
            :active-filter="params.report_status"
            @onUpdateFilter="$event => params.report_status = $event"
            active-class="border-purple-600 bg-purple-600 text-white shadow-inner">
            Tous
          </filter-button>

          <filter-button id="uncompleted-filter-button"
            value="uncompleted"
            :active-filter="params.report_status"
            @onUpdateFilter="$event => params.report_status = $event"
            active-class="border-purple-600 bg-purple-600 text-white shadow-inner">
            À Compléter
          </filter-button>

          <filter-button id="completed-filter-button"
            value="completed"
            :active-filter="params.report_status"
            @onUpdateFilter="$event => params.report_status = $event"
            active-class="border-purple-600 bg-purple-600 text-white shadow-inner">
            Complété(s)
          </filter-button>
        </div>

        <!-- par période -->
        <div class="flex flex-wrap">
          <filter-button id="current-day-filter-button"
            value="current_day"
            :active-filter="params.period"
            @onUpdateFilter="$event => params.period = $event">
            Aujourd'hui
          </filter-button>

          <filter-button id="current-week-filter-button"
            value="current_week"
            :active-filter="params.period"
            @onUpdateFilter="$event => params.period = $event">
            Cette Semaine
          </filter-button>

          <filter-button id="current-month-filter-button"
            value="current_month"
            :active-filter="params.period"
            @onUpdateFilter="$event => params.period = $event">
            Ce Mois-ci
          </filter-button>

          <filter-button id="current-year-filter-button"
            value="current_year"
            :active-filter="params.period"
            @onUpdateFilter="$event => params.period = $event">
            Cette année
          </filter-button>

            <filter-button id="next-week-filter-button"
              value="next_week"
              :active-filter="params.period"
              @onUpdateFilter="$event => params.period = $event">
              Semaine + 1
            </filter-button>

            <filter-button id="next-month-filter-button"
              value="next_month"
              :active-filter="params.period"
              @onUpdateFilter="$event => params.period = $event">
              Mois + 1
            </filter-button>
        </div>
      </div>
      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >

        <!-- Items -->
        <transition-group v-if="reports && reports.length" tag="ul">
          <li v-for="(report, index) in reports" :key="report.id">
            <report-item
              :delay-anim="index / 10"
              :id="`report-${report.id}-${index}`"
              :report="report"
              @alert="handleOpenAlertModal"
            />
          </li>
        </transition-group>

        <!-- Loading -->
        <spinner v-else-if="status === $const.API.STATUS.LOADING" :is-visible="true"></spinner>

        <!-- No report -->
        <div v-else class="left-0 flex flex-col justify-center items-center">
          <p class="text-gray-500 text-center px-10">{{ $t('page.reports.empty') }}</p>
        </div>

      </transition>

      <!-- more -->
      <div class="flex flex-col justify-center items-center pb-10 pt-4">
        <transition mode="out-in"
          enter-active-class="animated fadeIn faster-x2"
          leave-active-class="animated fadeOut faster-x2">
          <moon-loader
            class="v-spinner-custom"
            v-if="status === $const.API.STATUS.LOADING"
            :color="$const.MISC.SPINNER.COLOR"
          ></moon-loader>
        </transition>
      </div>
    </div>

    <!-- modal alert -->
    <transition
      enter-active-class="animated fadeIn faster-x2"
      leave-active-class="animated fadeOut faster-x2"
    >
      <alert-modal
        v-if="isAlertModalOpen"
        :title="alert.title"
        :message="alert.message"
        type="forbidden"
        @cancel="handleCancelAlertModal"
      ></alert-modal>
    </transition>

  </div>
</template>

<script>

import ReportItem from '../../components/Reports/ReportItem.vue';
import Spinner from '../../components/Spinner.vue';
import FilterButton from '../../components/Filter/FilterButton.vue';


export default {
  name: 'list-report-page',
  components: {
    ReportItem,
    Spinner,
    FilterButton,
  },
  data() {
    return {
      params: {
        limit: 10,
        offset: 0,
        period: 'current_day',
        report_status: 'all',
      },
      isAlertModalOpen: false,
      alert: {
        title: '',
        message: '',
      },
    };
  },
  watch: {
    'params.period'() {
      this.clearReports();
      this.fetchReports();
    },
    'params.report_status'() {
      this.clearReports();
      this.fetchReports();
    }
  },
  methods: {
    fetchReports() {
      if (this.status !== this.$const.API.STATUS.LOADING) {
        this.$store.dispatch('reportsModule/list', this.params).then(() => {
          // Handle invalid token even if user is authenticated
          if (this.$store.getters['reportsModule/getStatus'] === this.$const.API.STATUS.UNAUTHORIZED) {
            this.$store.dispatch('authModule/logout').then(() => {
              this.$router.push({
                name: this.$const.NAVIGATION.LOGIN_PAGE.NAME,
              });
            });
          }
        });
      }
    },
    handleOpenAlertModal(reportStatus) {
      if (reportStatus === 1) {
        this.alert.title = 'Rapport déjà envoyé !';
        this.alert.message = 'Vous avez déjà complété et envoyé ce rapport. Celui-ci ne peut plus être modifié.';
      } else {
        this.alert.title = 'Rendez-vous non terminé !';
        this.alert.message = 'Vous serez en mesure de le remplir uniquement à partir de la date de fin du rendez-vous.';
      }

      this.isAlertModalOpen = true;
    },
    handleCancelAlertModal() {
      this.isAlertModalOpen = false;
    },
    handleFetchMore() {
      if (this.status !== this.$const.API.STATUS.LOADING) {
        if (this.params.offset !== this.reports.length) {
          if (this.reports.length % 10 === 0) {
            this.params.offset = this.reports.length;
            this.fetchReports();
          }
        }
      }
    },
    clearReports() {
      this.params.limit = 10;
      this.params.offset = 0;
      this.$store.commit('reportsModule/setReports', []);
    },
  },
  computed: {
    reports: {
      get() {
        return this.$store.getters['reportsModule/getReports'];
      },
    },
    status() {
      return this.$store.getters['reportsModule/getStatus'];
    },
  },
  destroyed() {
    this.$store.commit('reportsModule/setReports', []);
  },
  mounted() {
    this.$store.dispatch('reportsModule/list', this.params).then(() => {
      // Handle invalid token even if user is authenticated
      if (this.$store.getters['reportsModule/getStatus'] === this.$const.API.STATUS.UNAUTHORIZED) {
        this.$store.dispatch('authModule/logout').then(() => {
          this.$router.push({ name: this.$const.NAVIGATION.LOGIN_PAGE.NAME });
        });
      }
    });
  },
};
</script>
