<template>
  <div>

    <top-bar>
      <div class="h-full flex items-center" slot="left">
        <back-button slot="left"/>
        <p class="px-6 text-gray-800 font-medium text-sm">Newsletter</p>
      </div>
    </top-bar>

    <div class="container mx-auto p-4 h-screen bg-white">
      <!-- admin : newsletter form -->
        <newsletter-form
          @submit="handleSubmitNewsletter"
          :is-sending="isSendingMails"
          title="Envoyer un email à tous mes partenaires"
        ></newsletter-form>
    </div>

    <!-- confirm modal -->
    <alert-modal
        v-if="isConfirmModalVisible"
        type="dialog"
        title="Confirmer l'envoi ?"
        message="Souhaitez-vous vraiment envoyer cette newsletter à tous vos partenaires ?"
        @cancel="isConfirmModalVisible = false"
        @confirm="confirmSubmitNewsletter"
    />

    <!-- sucess modal -->
    <alert-modal
      type="success"
      title="Newsletter"
      message="Newsletter envoyée avec succès !"
      v-if="isSuccessModalVisible"
      @cancel="isSuccessModalVisible = false"
      label-close-button="Fermer"
    ></alert-modal>

  </div>
</template>

<script>
import NewsletterForm from '../../components/Forms/NewsletterForm.vue';

export default {
  name: 'newsletter-admin-page',
  data() {
    return {
      isSendingMails: false,
      isSuccessModalVisible: false,
      isConfirmModalVisible: false,
      newsletterForm: null,
    };
  },
  components: {
    NewsletterForm,
  },
  methods: {
    async confirmSubmitNewsletter() {
      this.isConfirmModalVisible = false;

      if (this.newsletterForm) {
        this.isSendingMails = true;
        const endpoint = `${this.$const.API.BASE_URL}${this.$const.API.ENDPOINTS.API_ENDPOINTS.SEND_NEWSLETTER}`;
        const config = {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };

        // eslint-disable-next-line no-useless-catch
        try {
          const { subject, content, attachment } = this.newsletterForm;

          const formData = new FormData();
          formData.append('subject', subject);
          formData.append('content', content);

          if (attachment) {
            formData.append('attachment', attachment);
          }

          const { data: { message } } = await this.$axios.post(endpoint, formData, config);

          if (message === 'success') {
            this.isSuccessModalVisible = true;
          } else {
            this.$store.dispatch('authModule/logout').then(() => {
              this.$router.push({
                name: this.$const.NAVIGATION.LOGIN_PAGE.NAME,
              });
            });
          }

          this.isSendingMails = false;
        } catch (error) {
          throw error;
        }
      }
    },
    handleSubmitNewsletter(newsletterForm) {
      this.newsletterForm = newsletterForm;
      this.isConfirmModalVisible = true;
    },
  },
  computed: {
    token: {
      get() {
        return this.$store.getters['authModule/getToken'];
      },
      set(value) {
        this.$store.commit('authModule/setToken', value);
      },
    },
  }
};
</script>

<style>

</style>
