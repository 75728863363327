<template>
  <div class="relative inline-block text-left">
    <button data-button="dropdown" @click="$emit('active')" type="button" class="inline-flex justify-center w-full rounded-md px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
      <vertical-dots-icon data-button="dropdown" class="vertical-dots-icon"></vertical-dots-icon>
    </button>

    <transition
      mode="out-in"
      enter-active-class="animated fadeInRight faster-x2"
      leave-active-class="animated fadeOutRight faster-x2"
    >
      <div v-if="isActive" class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="py-1">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <p
            v-for="(option,index) in options"
            :key="`${index}-dropdowns-item`"
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            @click="$emit('select', option.key)"
          >{{ option.label }}</p>
        </div>
      </div>
    </transition>


  </div>
</template>

<script>
import { ICONS } from '../../constants';

const { common: { VerticalDotsIcon } } = ICONS;

export default {
  name: 'simple-drop-downs',
  data() {
    return {
      isShowed: false,
    }
  },
  components: {
    VerticalDotsIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .vertical-dots-icon
    height 15px
    width 15px
    color theme('colors.purple.800')
    fill theme('colors.purple.800')
</style>
