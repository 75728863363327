<template>
<!-- eslint-disable max-len -->
<div>
    <top-bar>
      <div class="h-full flex items-center" slot="left">
      </div>
    </top-bar>

    <div class="container mx-auto p-4 bg-white">
      <div class="flex flex-col justify-center items-center">

        <button
          @click="goTo($const.NAVIGATION.NEWSLETTER_PAGE.NAME)"
          class="mb-2 text-lg mx-auto border-2 border-purple-800 text-white bg-purple-800 w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline"
        >
          Créer une newsletter
        </button>

        <!-- from logout mixin -->
        <button
          @click="isConfirmLogoutModalVisible = !isConfirmLogoutModalVisible"
          class="text-lg mx-auto border-2 border-yellow-750 text-yellow-750 bg-white w-full font-bold py-2 rounded focus:outline-none focus:shadow-outline"
        >
          Déconnexion
        </button>

        <!-- go to admin pannel -->
        <float-button @tap="goTo($const.NAVIGATION.HOME_ADMIN_PAGE.NAME)" v-if="userRole === 'ROLE_ADMIN'" >
          <admin-icon></admin-icon>
        </float-button>

        <!-- from logout mixin -->
        <transition
          mode="out-in"
          enter-active-class="animated fadeIn faster-x2"
          leave-active-class="animated fadeOut faster-x2"
        >
          <alert-modal
            type="dialog"
            title="Déconnexion"
            message="Souhaitez-vous vraiment vous déconnecter ?"
            v-if="isConfirmLogoutModalVisible"
            @cancel="isConfirmLogoutModalVisible = false"
            @confirm="logout"
          ></alert-modal>
        </transition>

      </div>
    </div>

</div>
</template>

<script>

import logout from '../../mixins/logout';
import FloatButton from '../../components/Buttons/FloatButton.vue';

import { ICONS } from '../../constants';

const { common: { AdminIcon } } = ICONS;

export default {
  name: 'settings-page',
  mixins: [logout],
  components: {
    FloatButton,
    AdminIcon,
  },
  data() {
    return {
    };
  },
  computed: {
    userRole() {
      return this.$store.getters['authModule/getUserRole'];
    },
  },
};
</script>
